import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconStarActive = ({ className, onClick }) => (
  <svg className={className} onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.00021 0.241943C9.33253 0.241943 9.6349 0.434033 9.77612 0.734858L12.0233 5.52207L17.0313 6.29177C17.3487 6.34056 17.6123 6.56273 17.714 6.86737C17.8158 7.172 17.7387 7.50796 17.5144 7.73777L13.8663 11.4741L14.7289 16.7628C14.7817 17.0868 14.6449 17.4125 14.3765 17.6015C14.1082 17.7906 13.7554 17.8098 13.4681 17.6509L9.00021 15.1802L4.53227 17.6509C4.245 17.8098 3.89224 17.7906 3.62387 17.6015C3.3555 17.4125 3.21867 17.0868 3.27152 16.7628L4.13415 11.4741L0.486062 7.73777C0.261682 7.50796 0.184605 7.172 0.286377 6.86737C0.388148 6.56273 0.651686 6.34056 0.969141 6.29177L5.97707 5.52207L8.2243 0.734858C8.36552 0.434033 8.66789 0.241943 9.00021 0.241943ZM9.00021 3.11621L7.33475 6.66409C7.21341 6.92259 6.9713 7.10368 6.68905 7.14706L2.87724 7.73292L5.66307 10.5861C5.8532 10.7809 5.93956 11.0543 5.89574 11.3229L5.24764 15.2964L8.58542 13.4506C8.84353 13.3079 9.15689 13.3079 9.415 13.4506L12.7528 15.2964L12.1047 11.3229C12.0609 11.0543 12.1472 10.7809 12.3373 10.5861L15.1232 7.73292L11.3114 7.14706C11.0291 7.10368 10.787 6.92259 10.6657 6.66409L9.00021 3.11621Z" fill={colors.primary} />
  </svg>
);

IconStarActive.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.string,
};

IconStarActive.defaultProps = {
  className: '',
  onClick: null,
};
