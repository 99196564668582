import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './card-label.scss';

export const CardLabel = ({
  children,
  className,
}) => (
  <div className={classNames('card-label px-1 py-0_5 text-small font-weight-bold', className)}>
    {children}
  </div>
);

CardLabel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

CardLabel.defaultProps = {
  className: null,
};
