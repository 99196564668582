import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  RouteLink,
  FormattedTag,
} from 'core/components';

import './game-section-header.scss';

export const GameSectionHeader = ({ className, headerKey, route }) => (
  <RouteLink
    to={route}
    className={classNames('d-flex justify-content-between align-items-center py-2 text-decoration-none header-wrapper', className)}
  >
    <div className="d-flex align-items-center">
      <FormattedTag
        id={headerKey}
        className="font-weight-bold letter-spacing-default text-uppercase games-title text-white"
      />
    </div>
    <FormattedTag
      id="see-all"
      className="font-weight-strong show-more"
    />
  </RouteLink>
);

GameSectionHeader.propTypes = {
  className: PropTypes.string,
  headerKey: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
};

GameSectionHeader.defaultProps = {
  className: '',
};
