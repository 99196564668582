import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';

import './games-grid-preloader.scss';

export const GamesGridPreloader = ({
  rows, itemsCount, isGamePage, className
}) =>
  R.times(row => (
    <div
      key={row}
      className={classNames(className,
        {
          'd-flex row-preloader': isGamePage,
          'grid-preloader mb-1 mb-lg-2': !isGamePage,
        })}
    >
      {R.times(i => (
        <div
          key={i}
          className={classNames('preloader-item preloader',
            className, {
              'is-game-page mx-0_25': isGamePage,
            })}
        />
      ), itemsCount)}
    </div>
  ), rows);

GamesGridPreloader.propTypes = {
  itemsCount: PropTypes.number,
  rows: PropTypes.number,
  isCarousel: PropTypes.bool,
  isGamePage: PropTypes.bool,
};

GamesGridPreloader.defaultProps = {
  itemsCount: 5,
  isCarousel: false,
  isGamePage: false,
  rows: 1,
};
