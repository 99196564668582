import React from 'react';
import PropTypes from 'prop-types';

export const IconGames = ({ className }) => (
  <svg width="28" height="28" viewBox="0 0 28 28" className={className}>
    <path className="icon-filled-path" fill="#FFF" fillRule="nonzero" d="M25.413 21c-.51.64-1.26 1-2.08 1a2.654 2.654 0 0 1-2.44-1.61l-.439-1.001c.025-.018.054-.031.078-.049.89-.63 1.47-1.67 1.47-2.84 0-1.93-1.57-3.5-3.5-3.5-1.759 0-3.204 1.308-3.449 3h-2.102c-.245-1.692-1.69-3-3.449-3-1.93 0-3.5 1.57-3.5 3.5 0 1.17.58 2.21 1.47 2.84.024.018.051.03.075.047l-.439 1.003c-.42.98-1.38 1.61-2.44 1.61-.82 0-1.57-.36-2.08-1-.51-.63-.7-1.45-.52-2.24l2.48-11.19C4.758 6.64 5.558 6 6.508 6h1.35c.52 0 1.04.21 1.41.59l.54.53c.56.57 1.32.88 2.12.88h4.145c.8 0 1.56-.31 2.12-.88l.54-.53c.37-.38.89-.59 1.41-.59h1.35c.95 0 1.75.64 1.96 1.57l2.48 11.19c.18.79-.01 1.61-.52 2.24m-8.41-4.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5c0 .33-.11.64-.3.89-.27.37-.7.61-1.2.61-.31 0-.6-.09-.84-.26-.4-.26-.66-.72-.66-1.24m-6.66 1.24c-.242.17-.532.26-.84.26-.5 0-.93-.24-1.2-.61-.19-.25-.3-.56-.3-.89 0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5c0 .52-.26.98-.66 1.24m17.55.59l-2.49-11.2A3.982 3.982 0 0 0 21.492 4h-1.35c-1.05 0-2.08.43-2.83 1.17l-.53.54c-.19.18-.45.29-.71.29h-4.145c-.26 0-.52-.11-.71-.29l-.53-.54A4.065 4.065 0 0 0 7.858 4h-1.35c-1.89 0-3.5 1.29-3.91 3.13l-2.49 11.2c-.3 1.38.03 2.82.92 3.92A4.636 4.636 0 0 0 4.668 24c1.86 0 3.54-1.1 4.28-2.81l.517-1.193.037.003c1.39 0 2.585-.822 3.15-2h2.7a3.498 3.498 0 0 0 3.15 2l.034-.002.517 1.192a4.658 4.658 0 0 0 4.28 2.81c1.43 0 2.75-.64 3.64-1.74a4.679 4.679 0 0 0 .92-3.93" />
  </svg>
);

IconGames.propTypes = {
  className: PropTypes.string,
};

IconGames.defaultProps = {
  className: '',
};
